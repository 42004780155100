@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-3xs: 10px;
  --font-size-mini: 15px;
  --font-size-12xl: 31px;
  --font-size-xl: 20px;
  --font-size-7xl: 26px;
  --font-size-sm: 14px;
  --font-size-base: 16px;

  /* Colors */
  --color-linen: #f4efe9;
  --color-salmon: #f27762;
  --black: #1d1928;
  --color-gray-100: #141414;
  --color-darkslategray: #003b4a;
  --semantic-colors-bg-bg-primary: #fff;
  --color-deepskyblue: rgba(39, 180, 239, 0.2);
  --color-whitesmoke: #f0f0f0;
  --color-lightgray: #d6d6d6;
  --color-black: #000;
  --semantic-colors-stroke-stroke-primary: #c9d9f2;
  --color-lavenderblush: #fdf1f3;
  --color-brown: #c12f49;
  --color-floralwhite: #fef6ec;
  --color-chocolate: #d88311;
  --color-aliceblue: #dbf0ff;
  --color-steelblue: #1882ae;

  /* Gaps */
  --gap-14xl: 33px;
  --gap-base: 16px;
  --gap-mid: 17px;
  --gap-9xs: 4px;
  --gap-31xl: 50px;
  --gap-xs: 12px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-2xl: 21px;
  --padding-12xs: 1px;
  --padding-6xl: 25px;
  --padding-7xl: 26px;
  --padding-11xs: 2px;
  --padding-6xs: 7px;
  --padding-9xs-5: 3.5px;
  --padding-4xl: 23px;
  --padding-320xl: 339px;
  --padding-31xl: 50px;
  --padding-59xl: 78px;
  --padding-59xl-5: 78.5px;
  --padding-xl: 20px;
  --padding-10xl: 29px;
  --padding-5xs: 8px;
  --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-10xs: 3px;
  --padding-9xs: 4px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-5xs: 8px;
  --br-3xl: 22px;
  --br-9xs: 4px;
}
